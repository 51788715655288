<template>
  <div>
    <b-row>
      <b-col cols="12" md="4">
        <b-card class="card-tiny-line-stats" body-class="pb-50">
          <h6>التصنيفات</h6>
          <h2 class="font-weight-bolder mb-1">{{ categoriesCount }}</h2>
          <!-- chart -->
          <vue-apex-charts
            height="70"
            :options="statisticsProfit.chartOptions"
            :series="statisticsProfit.series"
          />
        </b-card>
      </b-col>
      <b-col cols="12" md="4">
        <b-card>
            <b-row>
            <b-col cols="6">
              <b-card-title class="mb-1">النشاطات</b-card-title>
              <div class="font-small-2">هذا الشهر</div>
              <b-card-text class="text-muted font-small-2">
                <span> نشاطات هذا الشهر</span>
                <span class="font-weight-bolder"> {{ thisMonthActivitesCount }}</span>
              </b-card-text>
            </b-col>
            <b-col cols="6" class="stats">
              <h2 class="mb-1 font-weight-bolder">
                {{ activitesCount }}
              </h2>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12" md="4">
        <statistic-card-with-area-chart
          v-if="data.ordersRecevied"
          color="warning"
          statistic-title="الطلبات"
          :statistic="ordersCount"
          :chart-data="data.ordersRecevied.series"
        />
      </b-col>
      <b-col cols="12">
        <b-card v-if="statistics.categoriesName.length" no-body class="card-revenue-budget">
          <b-row class="mx-0">
            <b-col md="12" class="revenue-report-wrapper">
              <div
                class="
                  d-sm-flex
                  justify-content-between
                  align-items-center
                  mb-3
                "
              >
                <h4 class="card-title mb-50 mb-sm-0">المنتجات حسب التصنيف</h4>
              </div>
              <vue-apex-charts
                id="revenue-report-chart"
                type="bar"
                height="230"
                :options="options"
                :series="series"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<style scoped>
.stats {
  height: 134px;
  align-items: center;
  place-content: center;
  display: flex;
}
.mutedActivity {
  position: absolute;
  bottom: 0;
}
</style>
<script>
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import { BRow, BCol } from "bootstrap-vue";
import { kFormatter } from "@core/utils/filter";

import Ripple from "vue-ripple-directive";
import { mapActions, mapState } from "vuex";
const $trackBgColor = "#EBEBEB";
export default {
  components: {
    VueApexCharts,
    StatisticCardWithAreaChart,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      statisticsProfit: {
        charRadialtOptions: {
          chart: {
            type: "donut",
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
        series: [
          {
            data: [0, 20, 5, 30, 15, 45],
          },
        ],
        chartOptions: {
          chart: {
            type: "line",
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          grid: {
            borderColor: $trackBgColor,
            strokeDashArray: 5,
            xaxis: {
              lines: {
                show: true,
              },
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
            padding: {
              top: -30,
              bottom: -10,
            },
          },
          stroke: {
            width: 3,
          },
          colors: [$themeColors.info],
          markers: {
            size: 2,
            colors: $themeColors.info,
            strokeColors: $themeColors.info,
            strokeWidth: 2,
            strokeOpacity: 1,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [
              {
                seriesIndex: 0,
                dataPointIndex: 5,
                fillColor: "#ffffff",
                strokeColor: $themeColors.info,
                size: 5,
              },
            ],
            shape: "circle",
            radius: 2,
            hover: {
              size: 3,
            },
          },
          xaxis: {
            labels: {
              show: true,
              style: {
                fontSize: "0px",
              },
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            x: {
              show: false,
            },
          },
        },
      },
      data: {
        ordersRecevied: {
          series: [
            {
              name: "Orders",
              data: [10, 15, 8, 15, 7, 12, 8],
            },
          ],
          analyticsData: {
            orders: 38400,
          },
        },
        revenueReport: {
          series: [
            {
              name: "Earning",
              data: [95, 177, 284, 256, 105, 63, 168, 218, 72],
            },
          ],
        },
      },
      revenueReport: {
        chartOptions: {
          chart: {
            stacked: true,
            type: "bar",
            toolbar: { show: false },
          },
          grid: {
            padding: {
              top: -20,
              bottom: -10,
            },
            yaxis: {
              lines: { show: false },
            },
          },
          xaxis: {
            categories: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
            ],
            labels: {
              style: {
                colors: "#6E6B7B",
                fontSize: "0.86rem",
                fontFamily: "Montserrat",
              },
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          colors: [$themeColors.primary, $themeColors.warning],
          plotOptions: {
            bar: {
              columnWidth: "17%",
              endingShape: "rounded",
            },
            distributed: true,
          },
          yaxis: {
            labels: {
              style: {
                colors: "#6E6B7B",
                fontSize: "0.86rem",
                fontFamily: "Montserrat",
              },
            },
          },
        },
      },
      budgetChart: {
        options: {
          chart: {
            height: 80,
            toolbar: { show: false },
            zoom: { enabled: false },
            type: "line",
            sparkline: { enabled: true },
          },
          stroke: {
            curve: "smooth",
            dashArray: [0, 5],
            width: [2],
          },
          colors: [$themeColors.primary, "#dcdae3"],
          tooltip: {
            enabled: false,
          },
        },
      },
    };
  },
  created() {
    this.getStatitics();
  },
  computed: {
    ...mapState({
      categories: (state) => state.home.statistics.categories,
      orders: (state) => state.home.statistics.orders,
      categoriesCount: (state) => state.home.statistics.categoriesCount,
      activitesCount: (state) => state.home.statistics.activitesCount,
      thisMonthActivitesCount: (state) =>
        state.home.statistics.thisMonthActivitesCount,
      ordersCount: (state) => state.home.statistics.ordersCount,
      products: (state) => state.home.statistics.products,
      statistics : (state) => state.home.statistics,
      options : (state) => state.home.options,
      series : (state) => state.home.series,
    }),
  },
  methods: {
    kFormatter,
    ...mapActions(["getStatitics"]),
  },
};
</script>
